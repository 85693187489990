import { render, staticRenderFns } from "./Rigister.vue?vue&type=template&id=24ab230a&scoped=true&"
import script from "./Rigister.vue?vue&type=script&lang=js&"
export * from "./Rigister.vue?vue&type=script&lang=js&"
import style0 from "./Rigister.vue?vue&type=style&index=0&id=24ab230a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ab230a",
  null
  
)

export default component.exports